import { Component } from "@angular/core";
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

export let browserRefresh = false;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "inniver";
  subscription: Subscription;
  
    // wersyfikacja przeładowania F5 strony (refresh page)
  constructor(private router: Router) {
      this.subscription = router.events.subscribe((event) => {
          if (event instanceof NavigationStart) {
            browserRefresh = !router.navigated;
          }
      });
    }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'adm',
    loadChildren: () => import('../../components/adm/adm.module').then(m => m.AdminModule),
    data: {
      breadcrumb: "Admin"
    }
  },
  {
    path: 'pub',
    loadChildren: () => import('../../components/pub/pub.module').then(m => m.PublisherModule),
    data: {
      breadcrumb: "Publisher"
    }
  },
  {
    path: 'faq',
    loadChildren: () => import('../../components/faq/faq.module').then(m => m.FaqModule),
    data: {
      breadcrumb: "Faq"
    }
  },
  {
    path: 'terms',
    loadChildren: () => import('../../components/policy/policy.module').then(m => m.policyModule),
    data: {
      breadcrumb: "Terms"
    }
  }
];

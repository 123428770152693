export class Account
  {
    id?: number;
    username?: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    profileImage?: any;
    tax?: string;
    email?: string;
    city?: string;
    zipCode?: string;
    country?: any;
    roles?: any;
    about?: string;
    address?: string;
    balance?: number;
    pending?: number;
    rate?: number;
    status?: number;
    payoutTerm?: any;
  }

  export class myProfile{
    username?: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    tax?: string;
    email?: string;
    city?: string;
    zipCode?: string;
    country?: any;
    roles?: any;
    about?: string;
    address?: string;
    rate?: number;
    payoutTerm?: any;
    profileImage?: any;
  }

  export class AccountUpdate
  {
    username?: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    profileImage?: any;
    tax?: string;
    email?: string;
    city?: string;
    zipCode?: string;
    countryId?: any;
    roleIds: any[] = [];
    about?: string;
    address?: string;
    rate?: any;
    paymentTerm?: any;
  }

  export class AccountRegister{
    username?: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    countryId: number;
    city: string;
    zipCode: string;
    address: string;
    company: string;
    about: string;
  }

  export class AccountMessage{
    messageToSent?: any;
  }

  export class AccountStatus{
    statusId?: number;
  }

  export class AccountConfig {
    id?: any;
    register?: boolean;
    lock?: boolean;
    chgpass?: boolean;
  }

  export class Advertiser {
    email?: string;
    phone?: string;
    about?: string;
    rating?: number;
  }

  export class Password {
    newPassword?: string;
    currentPassword?: string;
    repeatNewPassword?: string;
  }

  export class AccountImage {
    profileImage?: any;
  }

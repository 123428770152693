export const COMP_API = 'http://10.10.0.102:8080/api';
//export const COMP_API = 'http://localhost:8080/api';

// GCP
//export const API = 'https://api-inniver-dot-innivernew.ue.r.appspot.com/api/v1';
export const API = 'https://inniver.com/api/v1';
export const APIWS = 'https://inniver.com/ws/';

// Azure
//export const API = 'https://inniverhosting20211124093848.azurewebsites.net/api/v1';


import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { TokenStorageService } from './authentication/token-storage.service';//'../services/token-storage.service'

// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false
	public fullScreen = false;
	// --- dodane w inniver ---
	menuContent: number;	//1-admin+publisher 2-admin 3-publisher
	roles: any;
  	rolesName: string[] = [];
	public items: any;
	//public MENUITEMS: Menu[];
	// -----------
	constructor(private tokenStorageService: TokenStorageService) {

		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}

		this.roles = this.tokenStorageService.getUser().roles;
    	for(let i=0;i<this.roles.length;i++)
      		this.rolesName[i]=this.roles[i].name

		if(this.rolesName.includes('ROLE_ADMIN'))
		  {
			  if(this.rolesName.includes('ROLE_PUBLISHER'))
				  this.menuContent=1; //1-admin+publisher
			  else
				  this.menuContent=2; //2-admin
		  }
		else
			  this.menuContent = 3;	// 3-publisher


		//1-admin+publisher
		if(this.menuContent == 1){
			this.items = new BehaviorSubject<Menu[]>(this.MENUITEMS1);
		}

		//2-admin
		if(this.menuContent == 2){
			this.items = new BehaviorSubject<Menu[]>(this.MENUITEMS2);
		}

		// 3-publisher
		if(this.menuContent == 3){
			this.items = new BehaviorSubject<Menu[]>(this.MENUITEMS3);
		}
	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?:any) {
		this.screenWidth = window.innerWidth;
	}

	//1-admin+publisher
	MENUITEMS1: Menu[] = [
		{
			title: 'Admin', icon: 'home', type: 'sub', badgeType: 'primary', active: true, children: [					
				{ title: 'Dashboard', icon: 'home', badgeType: 'primary', path: '/adm/dashboard',   type: 'link' },
				{ title: 'My Profile', icon: 'home',  path: '/adm/profile',   type: 'link' },
				{ title: 'Accounts', type: 'sub', children: [
						{ path: '/adm/publishers', title: 'Publishers', type: 'link' },
						{ path: '/adm/advertisers', title: 'Advertisers', type: 'link' }
					]
				},
				{ title: 'Finance', type: 'sub', active: false, children: [
						{ path: '/adm/conversions', title: 'Conversions', type: 'link', bookmark: true  },
						{ path: '/adm/payments', title: 'Payments', type: 'link' },
					]
				},
				{
					title: 'Offers', type: 'sub', active: false, children: [
						{ path: '/adm/settings', title: 'Settings', type: 'link' },
						{ path: '/adm/offers', title: 'All offers', type: 'link', bookmark: true },
						{ path: '/adm/import', title: 'Import offers', type: 'link' },
						{ path: '/adm/fraud', title: 'Import fraud', type: 'link' },
						{ path: '/adm/black', title: 'Black list', type: 'link' }
					]
				},
				{ title: 'System settings', path: '/adm/sysset',   type: 'link' },
				{ title: 'System log', path: '/adm/log',   type: 'link' },
			]
		},
		{
			title: 'Publisher', icon: 'home', type: 'sub', badgeType: 'primary', active: true, children:
				[
					{
						title: 'Dashboard', icon: 'home', path: '/pub/dashboard',  type: 'link'
					},
					{
						title: 'Payments', icon: 'home',  path: '/pub/payments',   type: 'link'
					},
					{
						title: 'Offers', icon: 'home',  path: '/pub/offers',   type: 'link'
					},
					{
						title: 'Lockers', type: 'sub', active: false, children: [
							{ path: '/pub/gateways', title: 'Contents', type: 'link' },
							{ path: '/pub/files', title: 'Files', type: 'link', bookmark: true }
						]
					},
					{
						title: 'Statistics', type: 'sub', active: false, children: [
							{ path: '/pub/conversions', title: 'Conversions', type: 'link', bookmark: true },
								{ path: '/pub/analytics', title: 'Analytics', type: 'link', bookmark: true },
								// { path: '/maintenance', title: 'Analytics', type: 'link', bookmark: true },
								{ path: '/pub/worldmap', title: 'Live visitors', type: 'link', bookmark: true }
						]
					},
				]
		},
		// {
		// 	path: '/chat', title: 'Chat', icon: 'message-square', type: 'link', bookmark: true
		// },
		{
			path: '/faq', title: 'FAQ', icon: 'help-circle', type: 'link'
		},
		{
			path: '/terms', title: 'Policy terms', icon: 'file', type: 'link'
		},
	]

	// 2 - admin
	MENUITEMS2: Menu[] = [
		{
			title: 'Admin', icon: 'home', type: 'sub', badgeType: 'primary', active: true, children: [					
				{ title: 'Dashboard', icon: 'home', badgeType: 'primary', path: '/adm/dashboard',   type: 'link' },
				{ title: 'My Profile', icon: 'home',  path: '/adm/profile',   type: 'link' },
				{ title: 'Accounts', type: 'sub', children: [
						{ path: '/adm/publishers', title: 'Publishers', type: 'link' },
						{ path: '/adm/advertisers', title: 'Advertisers', type: 'link' }
					]
				},
				{ title: 'Finance', type: 'sub', active: false, children: [
						{ path: '/adm/conversions', title: 'Conversions', type: 'link', bookmark: true  },
						{ path: '/adm/payments', title: 'Payments', type: 'link' },
					]
				},
				{
					title: 'Offers', type: 'sub', active: false, children: [
						{ path: '/adm/settings', title: 'Settings', type: 'link' },
						{ path: '/adm/offers', title: 'All offers', type: 'link', bookmark: true },
						{ path: '/adm/import', title: 'Import offers', type: 'link' },
						{ path: '/adm/fraud', title: 'Import fraud', type: 'link' },
						{ path: '/adm/black', title: 'Black list', type: 'link' }
					]
				},
				{ title: 'System settings', path: '/adm/sysset',   type: 'link' },
				{ title: 'System log', path: '/adm/log',   type: 'link' },
			]
		},
		{
			path: '/faq', title: 'FAQ', icon: 'help-circle', type: 'link'
		},
		{
			path: '/terms', title: 'Policy terms', icon: 'file', type: 'link'
		}
	]

	// 3-publisher
	MENUITEMS3: Menu[] = [
		{
			title: 'Publisher', icon: 'home', type: 'sub', badgeType: 'primary', active: true, children:
				[
					{
						title: 'Dashboard', icon: 'home', path: '/pub/dashboard',  type: 'link'
					},
					{
						title: 'My Profile', icon: 'home',  path: '/pub/profile',   type: 'link'
					},
					{
						title: 'Payments', icon: 'home',  path: '/pub/payments',   type: 'link'
					},
					{
						title: 'Offers', icon: 'home',  path: '/pub/offers',   type: 'link'
					},
					{
						title: 'Lockers', type: 'sub', active: false, children: [
							{ path: '/pub/gateways', title: 'Contents', type: 'link' },
							{ path: '/pub/files', title: 'Files', type: 'link', bookmark: true }
						]
					},
					{
						title: 'Statistics', type: 'sub', active: false, children: [
							{ path: '/pub/conversions', title: 'Conversions', type: 'link', bookmark: true },
		  					{ path: '/pub/analytics', title: 'Analytics', type: 'link', bookmark: true },
		  					// { path: '/maintenance', title: 'Analytics', type: 'link', bookmark: true },
		  					{ path: '/pub/worldmap', title: 'Live visitors', type: 'link', bookmark: true }
						]
					},
				]
		},
		// {
		// 	path: '/chat', title: 'Chat', icon: 'message-square', type: 'link', bookmark: true
		// },
		{
			path: '/faq', title: 'FAQ', icon: 'help-circle', type: 'link'
		},
		{
			path: '/terms', title: 'Policy terms', icon: 'file', type: 'link'
		},
	]

	// Array
	//items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

}

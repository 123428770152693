import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Account, AccountConfig, Advertiser } from '../../../models/admModel/account.model';
import * as config from '../backend.config';
import { Page } from '../../../shared/filters/filters';

// ----------------------------------------------
// Service for Administrator
// Component:
// ----------------------------------------------

const ADDRS_API = config.COMP_API + '/adm';
const ADDRS_API1 = config.API + `/admin`;
const ADDRS_API2 = config.API + `/accounts`;
const ADDRS_API3 = config.API + `/publishers`;
const ADDRS_API4 = config.API;

// API description for all commnication with BACK-END for accounts management
// ----------------------------------------------
// BACK-END API
// ----------------------------------------------
//  Method    URLS                      Actions                   View



const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class AccountService {
  constructor(private http: HttpClient) { }

  getAccount(id: any): Observable<any> {
    return this.http.get(ADDRS_API1 + `/accounts/publishers/` + `${id}`);
  }

  getMyProfile(): Observable<any> {
    return this.http.get(ADDRS_API1 + `/my-profile`);
  }

  getMyProfilePublisher(): Observable<any> {
    return this.http.get(ADDRS_API3 + `/my-profile`);
  }

  updateMyProfile(data: any): Observable<any> {
    return this.http.put(ADDRS_API1 + `/my-profile`, data);
  }

  updateMyProfilePublisher(data: any): Observable<any> {
    return this.http.put(ADDRS_API3 + `/my-profile`, data);
  }

  getAllAccount(page: Page): Observable<any>{
    return this.http.get(ADDRS_API1 + `/accounts/publishers/?pageSize=` + page.size + `&pageNumber=` + page.pageNumber);
  }

  // /accounts/publishers?isAdminWithPublisherAccountIncluded=true&pageSize=10&pageNumber=1
  getAllPublishers(page: any): Observable<any>{
    return this.http.get(ADDRS_API1 + `/accounts/publishers?isAdminWithPublisherAccountIncluded=` + page.isAdminWithPublisherAccountIncluded + `&pageSize=` + page.pageSize + `&pageNumber=` + page.pageNumber);
  }

  updateAccount(id: any, data: any): Observable<any> {
    return this.http.put(ADDRS_API1 + `/accounts/publishers/` + `${id}`, data);
  }

  updateAccountImage(data: any): Observable<any> {
    return this.http.put(ADDRS_API2 + `/profile-image`, data);
  }

  updateAccountStatus(id: any, status: any): Observable<any> {
    return this.http.put(ADDRS_API1  + `/accounts/publishers/${id}` +`/status` , status);
  }

  deleteAccount(id: any): Observable<any> {
    return this.http.delete(ADDRS_API1  + `/accounts/publishers/` + `${id}`);
  }

  sendmailAccount(id: any, message: any): Observable<any> {
    return this.http.post(ADDRS_API1 + `/accounts/publishers/${id}` + '/sendemail', message);
  }

  changePass(data: any): Observable<any> {
    return this.http.put(ADDRS_API2 + `/change-password`, data);
  }

  getAccountConfig(id: any): Observable<AccountConfig> {
    return this.http.get('${ADDRS_API}' + '/accconfig/' + '${id}'); //`${baseUrl}/${id}`
  }

  getAllAdvertiser(): Observable<any> {
    return this.http.get(ADDRS_API4 + `/advertisers`);
  }

  updateAdvertiser(id: any, data: any): Observable<any> {
    return this.http.put(ADDRS_API4 + '/advertisers/' + `${id}`, data);
  }

  getAdvertiserStats(): Observable<any> {
    return this.http.get(ADDRS_API4 + `/advertisers/stats`);
  }

}

<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 footer-copyright">
      <p class="mb-0">
        Inniver [version {{version}}]
        <span class="pull-right">Copyright 2024 © Inniver Corp. All rights reserved.</span></p>
    </div>
  </div>
</div>

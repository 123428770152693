
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';

import { filter } from 'rxjs/operators';
import { map } from 'rxjs';
import { TokenStorageService } from '../../services/authentication/token-storage.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs:any;
  public title : string
  roles: any;
  rolesName: string[] = [];
  dashboardLink: string;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private tokenStorageService: TokenStorageService) {

    this.roles = this.tokenStorageService.getUser().roles;
    for(let i=0;i<this.roles.length;i++)
      this.rolesName[i]=this.roles[i].name

		if(this.rolesName.includes('ROLE_ADMIN'))
		  {
        this.dashboardLink = "/adm/dashboard";
		  }
		else
			  this.dashboardLink = "/pub/dashboard";

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe((route:any) => {
        let snapshot = this.router.routerState.snapshot;
        let title = route.snapshot.data['title'];
        let parent = route.parent.snapshot.data['breadcrumb'];
        let child = route.snapshot.data['breadcrumb'];
        this.breadcrumbs = {};
        this.title = title;
        this.breadcrumbs = {
          "parentBreadcrumb": parent,
          "childBreadcrumb": child
        }
      });
  }

  ngOnInit() {  }

  ngOnDestroy() {  }

}

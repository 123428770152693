import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../services/nav.service';

import { TokenStorageService } from '../../services/authentication/token-storage.service';
import { AccountService } from 'src/app/shared/services/admServices/accounts.service';
import { AccountImage } from '../../../models/admModel/account.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {

  public menuItems: Menu[] | any;
  public url: any;
  public fileurl: any;

  roles: any;
  rolesName: string[] = [];
  username: string;
  base64Img = new AccountImage();

  constructor(private router: Router, public navServices: NavService, private tokenStorageService: TokenStorageService, private accountService: AccountService) {
    
    this.roles = this.tokenStorageService.getUser().roles;
    this.username = this.tokenStorageService.getUser().username;
    for(let i=0;i<this.roles.length;i++)
      this.rolesName[i]=this.roles[i].name
    
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url)
              this.setNavActive(items)
            if (!items.children) return false
            items.children.filter(subItems => {
              if (subItems.path === event.url)
                this.setNavActive(subItems)
              if (!subItems.children) return false
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url)
                  this.setNavActive(subSubItems)
              })
              return
            })
            return
          }
          )
        }
      })
    })

    this.url = this.tokenStorageService.getUser().profileImage;
    if(this.tokenStorageService.getUser().profileImage==null)
      this.url = '../../../../../assets/images/user/user2.png';
  }

  // Active Nave state
  setNavActive(item:any) {
    this.menuItems.filter((menuItem:any) => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter((submenuItems:any) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item:any) {
    if (!item.active) {
      this.menuItems.forEach((a:any) => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach((b:any) => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
        return
      });
    }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;

      this.base64Img.profileImage = this.url;

      this.accountService.updateAccountImage(this.base64Img)
      .subscribe(
        data => {
          if(data.errors)
          {
            //console.log("Error: ", data.errors[0].message);
          }
        },
        error => {
          //console.log(error);
        });
    }
  }

  

}
